import { Notification } from '@xbotvn/mui';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { graphQLCaller } from '../../libs/backend';
import { arrayToCollection } from '../../libs/utils';
import { UNITS } from './constants';

function* update(data = {}) {
  yield put({
    type: UNITS.update,
    data,
  });
}

function* getUnits() {
  try {
    const { getUnits: results } = yield graphQLCaller(
      'units',
      `{
          getUnits {
            id
            name
            staffs
            admins
            nutribot
          }
        }`
    );
    yield update(arrayToCollection(results || []));
  } catch ({ message }) {
    yield* update();
    Notification.error(message);
  }
}

function* updateUnit({ id, data, onComplete }) {
  try {
    yield graphQLCaller(
      'units',
      `
    mutation updateUnitInformation($id: ID!, $information: UnitInput!) {
      updateUnitInformation(id: $id, information: $information)
    }
  `,
      {
        id,
        information: data,
      }
    );
    Notification.success('Cập nhật thành công.');
    yield put({
      type: UNITS.handlers.get,
    });
    onComplete();
  } catch ({ message }) {
    Notification.error(message);
    onComplete(message);
  }
}

export const handleGetUnits = () => ({
  type: UNITS.handlers.get,
});

export const handleUpdateUnit = (id, data, onComplete) => ({
  type: UNITS.handlers.update,
  id,
  data,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(UNITS.handlers.get, getUnits),
    yield takeEvery(UNITS.handlers.update, updateUnit),
  ]);
}
