import { Notification } from '@xbotvn/mui';
import { getAuth, signOut } from 'firebase/auth';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { auth, callAPI } from '../../libs/backend';
import { queryCatalogs } from './catalogs';
import { SIGN_OUT, USER } from './constants';

function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

function userSignedOut() {
  signOut(getAuth());
}

function* userSignedIn(user) {
  const { email, uid } = user;
  if (email) {
    try {
      const { collabUnit } = yield auth(email, uid);
      if (collabUnit) {
        yield* queryCatalogs();
        yield* update({
          uid,
          email,
          displayName: user?.displayName ?? email,
          photoURL: user?.photoURL ?? '',
          collabUnit,
          authorized: true,
        });
      } else {
        Notification.warn('Tài khoản chưa được cấp quyền sử dụng hệ thống.');
        yield* update({ authorized: true });
        userSignedOut();
      }
    } catch ({ message }) {
      yield* update({ authorized: true });
      userSignedOut();
      Notification.error(message);
    }
  } else {
    yield* update({ authorized: true });
  }
}

function* updateStaffs({ staffs, admins, onComplete }) {
  const { collabUnit } = (yield select())?.user ?? {};
  if (collabUnit?.id) {
    try {
      yield callAPI('api/updateStaffs', {
        collabID: collabUnit.id,
        staffs,
        admins,
      });

      yield put({
        type: USER.unit,
        staffs,
        admins,
      });
      Notification.success('Cập nhật thành công.');
      onComplete();
    } catch ({ message }) {
      Notification.error(message);
      yield* update();
      onComplete(message);
    }
  } else {
    Notification.error('Missing Collab ID');
    yield* update();
    onComplete('Missing Collab ID');
  }
}

export const handleUserUpdate = (information, onComplete) => ({
  type: USER.handlers.update,
  information,
  onComplete,
});

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: SIGN_OUT,
});

export const handleSwitchUnit = (information, onComplete) => ({
  type: USER.handlers.switch,
  information,
  onComplete,
});

export const handleActiveSchoolYear = (schoolYear) => ({
  type: USER.handlers.activeSchoolYear,
  schoolYear,
});

export const handleUpdateStaffs = (staffs, admins, onComplete) => ({
  type: USER.handlers.unit,
  staffs,
  admins,
  onComplete,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeEvery(SIGN_OUT, userSignedOut),
    yield takeLatest(USER.handlers.unit, updateStaffs),
  ]);
}
