import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import {
  AppBar as MAppBar,
  Avatar,
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@xbotvn/mui';
import { grey } from '@xbotvn/mui/colors';
import { compact, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { handleUserSignOut } from '../../redux/actions/user';

function cleanPath(pathname) {
  return get(compact(pathname.split('/')), 0, false);
}

function AppBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { photoURL, displayName, email, collabUnit } = useSelector(({ user }) => user);

  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    setActiveMenu(cleanPath(history.location.pathname));
    history.listen((location) => {
      setActiveMenu(cleanPath(location.pathname));
    });
  }, []);

  const unitName = useMemo(() => collabUnit?.name ?? 'Unkown', [collabUnit]);

  return (
    <MAppBar
      position="sticky"
      color="transparent"
      sx={{
        zIndex: 12,
        background: 'white',
        borderRadius: 0,
        boxShadow:
          '0px 4px 8px -2px rgba(16, 24, 64, 0.1), 0px 2px 4px -2px rgba(16, 24, 64, 0.06);',
      }}
    >
      <Toolbar
        sx={{ display: 'flex', minHeight: '56px !important', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRight: `1px solid ${grey[200]}`,
            cursor: 'pointer',
          }}
          onClick={() => history.push('/')}
        >
          <img style={{ width: 24, height: 'auto' }} src="/icon-192x192.png" alt="logo" />
          <Typography variant="h6" sx={{ marginLeft: 1 }}>
            {unitName}
          </Typography>
        </Box>
        <Stack spacing={1} direction="row" sx={{ flexGrow: 1, marginLeft: 2 }}>
          {collabUnit ? (
            <Tabs value={activeMenu} onChange={(e, value) => history.push(`/${value}`)}>
              <Tab key="nutribot" label="NUTRIBOT" value="nutribot" />
              <Tab key="unit" label="Quản trị" value="unit" />
            </Tabs>
          ) : null}
        </Stack>
        <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
          <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
            <Avatar alt={displayName || email} src={photoURL} />
            <Typography>{displayName || email}</Typography>
          </Stack>
          <IconButton onClick={() => dispatch(handleUserSignOut())}>
            <UilSignout />
          </IconButton>
        </Stack>
      </Toolbar>
    </MAppBar>
  );
}

export default AppBar;
