import axios from 'axios';
import { getAuth } from 'firebase/auth';

import { BACKEND } from '../config';

export async function callAPI(path, params) {
  const result = await axios.post(`${BACKEND}/${path}`, params, { withCredentials: true });
  return result.data;
}

export async function callFileAPI(path, params, isDownload = false) {
  const options = { withCredentials: true };
  if (isDownload) options.responseType = 'blob';
  const result = await axios.post(`${BACKEND}/${path}`, params, options);
  if (result.data) return result.data;
  throw Error('Unkown error');
}

export const auth = (email, uid) =>
  new Promise((resolve, reject) => {
    getAuth()
      .currentUser.getIdToken(true)
      .then(
        (token) => {
          axios
            .post(
              `${BACKEND}/api/auth`,
              {
                email,
                token,
                uid,
              },
              { withCredentials: true }
            )
            .then((user) => {
              if (user.data) resolve(user.data);
              else reject(Error('Invalid account'));
            })
            .catch(({ message }) => reject(Error(message)));
        },
        (message) => reject(Error(`Token failed: ${message}`))
      );
  });

export const signedOut = () => {
  axios.post(`${BACKEND}/api/signedOut`, {}, { withCredentials: true }).catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
  });
};

export async function graphQLCaller(collection, query, variables) {
  const res = await axios.post(
    `${BACKEND}/database/${collection}`,
    {
      query,
      variables,
    },
    {
      withCredentials: true,
    }
  );
  const { data } = res;
  const errors = data?.errors ?? [];
  if (errors.length) {
    throw Error(errors.map((err) => err?.message ?? '').join(', '));
  } else {
    return data?.data ?? {};
  }
}
