import { Notification } from '@xbotvn/mui';
import { put } from 'redux-saga/effects';

import { callAPI } from '../../libs/backend';
import { CATALOGS } from './constants';

function* query(category, id) {
  yield put({
    type: CATALOGS.handlers[category],
    id,
  });
}

function* update(category, id, data) {
  yield put({
    type: CATALOGS[category],
    id,
    data,
  });
}

function* querySystemCatalogs() {
  try {
    yield* query('system', 'cities');
    const cities = yield callAPI('api/cities');
    yield* update('system', 'cities', cities || {});
  } catch ({ message }) {
    Notification.error(message);
    yield* update('system', 'cities', {});
  }
}

export function* queryCatalogs() {
  yield* querySystemCatalogs();
}
