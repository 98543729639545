import { SIGN_OUT, UNITS } from '../actions/constants';

const initialState = {};

export default function units(state = initialState, action) {
  const { type, data } = action;

  switch (type) {
    case UNITS.handlers.get:
    case UNITS.handlers.update:
      return {
        ...state,
        handling: true,
      };
    case UNITS.update:
      return {
        data,
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
