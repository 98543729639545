import { Autocomplete, Stack, TextField } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

function Cities({ onChange, product, editForm, province, district, ward }) {
  const cities = useSelector(({ catalogs }) => catalogs.system?.cities?.data ?? {});
  const collabUnit = useSelector(({ user }) => user.collabUnit);

  const provinceOptions = useMemo(() => {
    if (!collabUnit) return [];
    const all = Object.keys(cities);
    if (!collabUnit[product]) return all;
    const allows = (collabUnit[product] ?? []).map((e) => e.slice(0, 2));
    return all.filter((e) => allows.includes(e));
  }, [cities, collabUnit, product]);

  const districtOptions = useMemo(() => {
    if (!collabUnit) return [];
    const all = Object.keys(cities?.[province]?.districts ?? {});
    if (!collabUnit[product]) return all;
    const allows = (collabUnit[product] ?? []).map((e) => e.slice(0, 6));
    return all.filter((e) => allows.includes(e) || allows.includes(`${e.slice(0, 2)}`));
  }, [cities, collabUnit, product, province]);

  const wardOptions = useMemo(
    () => Object.keys(cities?.[province]?.districts?.[district]?.wards ?? {}),
    [cities, province, district]
  );

  return (
    <Stack spacing={1} direction="row">
      <Autocomplete
        sx={{ width: 250 }}
        options={provinceOptions}
        getOptionLabel={(option) => cities[option]?.name ?? option}
        value={province}
        onChange={(e, value) => onChange(value, null, null)}
        renderInput={(params) => <TextField {...params} label="Tình/Thành" />}
        disabled={editForm}
      />
      <Autocomplete
        sx={{ width: 250 }}
        options={districtOptions}
        getOptionLabel={(option) => cities[province]?.districts?.[option]?.name ?? option}
        value={district}
        onChange={(e, value) => onChange(province, value, null)}
        renderInput={(params) => <TextField {...params} label="Quận/Huyện" />}
        disabled={editForm}
      />
      {editForm ? (
        <Autocomplete
          sx={{ width: 250 }}
          options={wardOptions}
          getOptionLabel={(option) =>
            cities[province]?.districts?.[district]?.wards?.[option]?.name ?? option
          }
          value={ward}
          onChange={(e, value) => onChange(province, district, value)}
          renderInput={(params) => <TextField {...params} label="Phường/Xã" />}
        />
      ) : null}
    </Stack>
  );
}

Cities.propTypes = {
  onChange: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  editForm: PropTypes.bool,
  province: PropTypes.string,
  district: PropTypes.string,
  ward: PropTypes.string,
};

Cities.defaultProps = {
  editForm: false,
  province: null,
  district: null,
  ward: null,
};

export default Cities;
