export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    update: 'USER_UPDATE_HANDLER',
    unit: 'USER_UPDATE_UNIT_HANDLERS',
  },
  update: 'USER_UPDATE',
  unit: 'USER_UPDATE_UNIT',
};

export const UNITS = {
  handlers: {
    get: 'UNITS_GET_HANDLER',
    update: 'UNITS_UPDATE_HANDLER',
  },
  update: 'UNIT_UPDATE',
};

export const CATALOGS = {
  handlers: {
    system: 'CATALOGS_QUERY_SYSTEM_HANDLER',
    app: 'CATALOGS_QUERY_APP_HANDLER',
    update: 'CATALOGS_UPDATE_APP_HANDLER',
  },
  system: 'CATALOGS_SYSTEM',
  app: 'CATALOGS_APP',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const SIGN_OUT = 'SIGN_OUT';
