import './libs/firebase';

import { Box, Notification, Typography, Waiting } from '@xbotvn/mui';
import { ThemeProvider } from '@xbotvn/mui/styles';
import { lightTheme } from '@xbotvn/mui/themes';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';

import packageJson from '../package.json';
import AuthorizedRoute from './AuthorizedRoute';
import { AppBar } from './components';
import { handleUpdateSettings } from './redux/actions/settings';
import { handleUserSignedIn } from './redux/actions/user';

const Welcome = React.lazy(() => import('./screens/Welcome'));
const Home = React.lazy(() => import('./screens/Home'));
const Nutribot = React.lazy(() => import('./screens/Nutribot'));
const Unit = React.lazy(() => import('./screens/Unit'));
const NotFound = React.lazy(() => import('./screens/NotFound'));

export default function App() {
  const dispatch = useDispatch();
  const { authorized, logged } = useSelector(({ user }) => ({
    authorized: user?.authorized ?? false,
    logged: user.email,
  }));

  useEffect(() => {
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) {
        document.body.style.backgroundColor = '#30404d';
      }
    }
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        Notification.warn('Link đã được mở trên một thiết bị khác. Xin vui lòng đăng nhập lại.');
      } else {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((err) => {
            Notification.error(err.message);
          });
      }
    }
    return onAuthStateChanged(getAuth(), (user) => {
      dispatch(handleUserSignedIn(user));
    });
  }, []);

  const ready = useMemo(() => authorized, [authorized]);

  return ready ? (
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter>
        <Suspense fallback={<Waiting />}>
          {logged ? <AppBar /> : null}
          <Box>
            <RouterSwitch>
              <Route exact path="/welcome">
                <Welcome />
              </Route>
              <AuthorizedRoute exact path="/unit" component={Unit} />
              <AuthorizedRoute exact path="/nutribot" component={Nutribot} />
              <AuthorizedRoute exact path="/" component={Home} />
              <Route component={() => <NotFound />} />
            </RouterSwitch>
          </Box>
        </Suspense>
      </BrowserRouter>
      <Box
        zIndex={10}
        sx={{
          height: 56,
          position: 'fixed',
          paddingLeft: '50px',
          paddingRight: '50px',
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'white',
        }}
      >
        <Typography variant="caption">
          {`COBOT v${packageJson.version} - © 2021, XBOT Technology JSC. All Rights Reserved.`}
        </Typography>
      </Box>
      <Notification.Container
        position="top-right"
        autoClose={3000}
        hideProgressBar
        pauseOnHover
        pauseOnFocusLoss
        closeOnClick
        draggable
        draggablePercent={80}
      />
    </ThemeProvider>
  ) : (
    <Waiting />
  );
}
