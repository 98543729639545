import { nanoid } from 'nanoid';

export const arrayToCollection = (arr, id = 'id', options = {}) => {
  const result = {};
  arr.forEach((item) => {
    if (item[id]) {
      result[item[id]] = {};
      if (options.preserve) result[item[id]][id] = item[id];
      Object.entries(item).forEach(([key, value]) => {
        if (key !== id && value !== null) {
          if (!(options.skip ?? []).includes(key)) result[item[id]][key] = value;
        }
      });
    }
  });
  return result;
};

export const getUniqueID = (obj = {}, size = 21) => {
  const id = nanoid(size);
  if (!obj[id]) return id;
  return getUniqueID(obj, size);
};

export const getUniqueElement = (arr = [], size = 21) => {
  const id = nanoid(size);
  if (!arr.includes(id)) return id;
  return getUniqueElement(arr, size);
};

export const transferData = (data, transform) => {
  const cleaned = {};
  Object.entries(data).forEach(([field, value]) => {
    cleaned[field] = transform(field, value);
  });
  return cleaned;
};

export const round = (value, dec = 2) => Number((value || 0).toFixed(dec));

export const getCitiesNames = (id, cities = {}) => {
  const ids = id.split('_');

  const names = [cities[ids[0]]?.name];
  if (ids.length > 1) {
    names.push(cities[ids[0]]?.districts?.[`${ids[0]}_${ids[1]}`]?.name);
    if (ids.length > 2) {
      names.push(
        cities[ids[0]]?.districts?.[`${ids[0]}_${ids[1]}`]?.wards?.[`${ids[0]}_${ids[1]}_${ids[2]}`]
          ?.name
      );
    }
  }
  return names;
};
