import { combineReducers } from 'redux';

import catalogs from './catalogs';
import settings from './settings';
import units from './units';
import user from './user';

const rootReducer = combineReducers({
  settings,
  catalogs,
  user,
  units,
});

export default rootReducer;
